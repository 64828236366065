import React from 'react';
const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(23, 24)"
          fill="currentColor"
          fillRule="evenodd"
          strokeLinecap="square"
          strokeWidth="0.945"
          d="M26.685 35.767l-3.394-9.717H7.861l-3.344 9.717H0L13.306 0h5.078l13.33 35.767h-5.029zM9.155 22.314h12.793L17.212 8.35 15.82 3.369h-.488L13.94 8.35 9.155 22.314z"
          fontSize="12"
          vectorEffect="non-scaling-stroke"></path>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
        L 11, 27
        L 11, 72
        L 50, 95
        L 89, 73
        L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
