import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(23, 24)"
          fill="currentColor"
          fillRule="evenodd"
          strokeLinecap="square"
          strokeWidth="0.945"
          d="M26.685 35.767l-3.394-9.717H7.861l-3.344 9.717H0L13.306 0h5.078l13.33 35.767h-5.029zM9.155 22.314h12.793L17.212 8.35 15.82 3.369h-.488L13.94 8.35 9.155 22.314z"
          fontSize="12"
          vectorEffect="non-scaling-stroke"></path>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
